import React from "react";

export default function LinedText({ text }) {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
      <div className="col-md-2">
        <hr className="lined-text" />
      </div>
      <div className="col-auto">
        <h4 className="text-green-primary text-center">{text}</h4>
      </div>
      <div className="col-md-2">
        <hr className="lined-text" />
      </div>
    </div>
    </div>
  );
}
