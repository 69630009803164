/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/dashboard/Sidebar";
import DataDashboard from "../components/dashboard/contents/DataDashboard";
import { faBars, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Category from "../components/dashboard/contents/Category";
import Agency from "../components/dashboard/contents/Agency";
import { useNavigate } from "react-router-dom";
import Users from "../components/dashboard/contents/Users";

export default function Dashboard() {
  const navigate = useNavigate();
  const [content, setContent] = useState("dashboard");
  const [total, setTotal] = useState(0);
  const [isActive, setIsActive] = useState(false);

  function handleSidebarToggle() {
    setIsActive((prevIsActive) => !prevIsActive);
  }

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      logout();
    }
  }, []);

  return (
    <div id="dashboard-wrapper" className="wraps d-flex">
      <div id="sidebar" className={`bg-dark text-white ${isActive ? "active" : "inactive"}`}>
        <div className="container h-100 overflow-auto">
          <Sidebar action={(val) => setContent(val)} active={handleSidebarToggle} />
        </div>
      </div>

      <div id="content" className={`${isActive ? "active" : "inactive"}`}>
        <div
          id="top-content"
          className="d-flex justify-content-center align-items-center h-50 w-100 bg-light position-relative"
        >
          <img
            src={require("../assets/images/home-banner-bg.png")}
            alt=""
            className="position-absolute top-0 start-0 end-0 w-100 h-100 z-1 darken-img"
            style={{ filter: "brightness(0.35)" }}
          />
          <div className="position-absolute top-0 start-0 end-0 z-2">
            <nav className="navbar navbar-dark">
              <div className="container-fluid">
                <div role={"button"} onClick={handleSidebarToggle} className="navbar-brand">
                  <FontAwesomeIcon icon={faBars} className="text-orange" />
                </div>
                <div role={"button"} className="navbar-brand" onClick={logout}>
                  <FontAwesomeIcon icon={faSignOut} className="text-orange" />
                </div>
              </div>
            </nav>
          </div>

          <div className="container text-center position-relative">
            <h1 className="display-3 text-orange">{content.toUpperCase()}</h1>
            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-2 p-1">
                <div className="container border text-center text-orange rounded-pill p-1 top-content-pill">
                  <span>Total : {total}</span>
                </div>
              </div>
              {content === "dashboard" ? (
                <div role={"button"} data-bs-toggle="modal" data-bs-target="#insertData" className="col-md-2 p-1">
                  <div className="container border text-center text-orange rounded-pill p-1 top-content-pill">
                    <span>+ Tambah Data</span>
                  </div>
                </div>
              ) : content === "category" ? (
                <div role={"button"} data-bs-toggle="modal" data-bs-target="#insertCategory" className="col-md-2 p-1">
                  <div className="container border text-center text-orange rounded-pill p-1 top-content-pill">
                    <span>+ Tambah Data</span>
                  </div>
                </div>
              ) : content === "skpd" ? (
                <div role={"button"} data-bs-toggle="modal" data-bs-target="#insertAgency" className="col-md-2 p-1">
                  <div className="container border text-center text-orange rounded-pill p-1 top-content-pill">
                    <span>+ Tambah Data</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {content === "dashboard" ? (
          <DataDashboard total={(val) => setTotal(val)} />
        ) : content === "category" ? (
          <Category total={(val) => setTotal(val)} />
        ) : content === "skpd" ? (
          <Agency total={(val) => setTotal(val)} />
        ) : content === "users" ? (
          <Users total={(val) => setTotal(val)} />
        ) : null}
      </div>
    </div>
  );
}
