import React, { useEffect, useState } from "react";
import "chart.js/auto";
import Line from "./shape/Line";

const LineChart = ({ cData, submitData }) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [cbItem, setCbItem] = useState([]);
  const [x, setX] = useState([]);

  const handleX = async (val) => {
    setX(val);
  };

  const handleData = (key, val) => {
    const index = checkedItems.findIndex((item) => item.label === key);
    if (index !== -1) {
      checkedItems.splice(index, 1);
      setCheckedItems([...checkedItems]);
    } else {
      setCheckedItems([
        ...checkedItems,
        (function () {
          var bgColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
          return {
            label: key,
            data: val,
            backgroundColor: bgColor,
            borderWidth: 1,
            borderColor: bgColor,
            pointBackgroundColor: bgColor,
            pointBorderWidth: 2,
            pointRadius: 5,
            pointHoverRadius: 8,
            pointHitRadius: 10,
          };
        })(),
      ]);
    }
  };

  useEffect(() => {
    let dt = { x: x, checkedItems: checkedItems };
    submitData(dt);
  }, [x, checkedItems, submitData]);

  useEffect(() => {
    setCbItem(cData);
  }, [cData]);

  return (
    <div className="container h-100">
      <div className="container-fluid border mb-3 pt-2 pb-2 rounded">
        <div className="container">
          <h6> Garis (x) :</h6>
          <div className="row">
            {Object.entries(cbItem).map(([key, values]) => (
              <div className="col-auto">
                <div className="form-check" key={key}>
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="x"
                      value={values}
                      onChange={() => handleX(values)}
                    />
                    <span>{key}</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container">
          <h6> Data :</h6>
          <div className="row">
            {Object.entries(cbItem).map(([key, values]) => (
              <div className="col-auto">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values}
                    id="flexCheckDefault"
                    onChange={() => handleData(key, values)}
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    {key}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <Line x={x} data={checkedItems} />
      </div>
    </div>
  );
};

export default LineChart;
