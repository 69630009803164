import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import UpdateCategory from "../modal/categories/Update";
import DeleteCategory from "../modal/categories/Delete";

export default function CategoryTables({ data, refreshTable }) {
  const [editData, setEditData] = useState({});

  const handleEdit = (id, name) => {
    setEditData({
      id: id,
      name: name,
    });
  };

  const columns = [
    {
      name: <h6 className="m-0 table-header fw-bold">Nama</h6>,
      selector: (row) => row.name,
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Total Data</h6>,
      selector: (row) => row.dataLength,
      center: true,
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Options</h6>,
      center: true,
      selector: (row) => {
        return (
          <div className="row">
            <div className="col">
              <h6>
                <FontAwesomeIcon
                  icon={faEdit}
                  role={"button"}
                  className="align-middle"
                  onClick={() => handleEdit(row.id, row.name)}
                  data-bs-toggle="modal"
                  data-bs-target="#updateCategory"
                />
              </h6>
            </div>
            <div className="col">
              <h6>
                <FontAwesomeIcon
                  icon={faTrash}
                  role={"button"}
                  className="align-middle"
                  onClick={() => handleEdit(row.id, row.name)}
                  data-bs-toggle="modal"
                  data-bs-target="#deleteCategory"
                />
              </h6>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <DataTable columns={columns} data={data} pagination dense />
      <UpdateCategory data={editData} refreshTable={() => refreshTable()} />
      <DeleteCategory data={editData} refreshTable={() => refreshTable()} />
    </div>
  );

  // return ;
}
