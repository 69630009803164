import React from "react";
import DataTable from "react-data-table-component";

export default function InsertTables({ tData }) {
  const columns = Object.keys(tData).map((key) => {
    return {
      name: key,
      selector: key,
      sortable: true,
    };
  });

  const maxLength = Math.max(...Object.values(tData).map((a) => a.length));

  const rows = Array.from({ length: maxLength }, (_, i) => {
    return Object.entries(tData).reduce((acc, [key, value]) => {
      acc[key] = value[i];
      return acc;
    }, {});
  });

  return <DataTable columns={columns} data={rows} pagination dense />;
}
