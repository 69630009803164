import { useState, useEffect } from "react";
import PieChart from "./shape/Pie";

function PieFunction({ cData, submitData }) {
  const [lbl, setLbl] = useState([]);
  const [chartData, setChartData] = useState([]);

  const handleChange = (key, val, event) => {
    if (event.target.checked) {
      if (!lbl.includes(key)) {
        setLbl([...lbl, key]);
        setChartData([
          ...chartData,
          val
        ]);
      }
    } else {
      setLbl(lbl.filter((item) => item !== key));
      setChartData(chartData.filter((item) => item.label !== key));
    }
  };

  useEffect(() => {
    let dt = { lbl: lbl, chartData: chartData };
    submitData(dt);
  }, [lbl, chartData, submitData]);
  
  return (
    <div>
      <div className="row border rounded p-2">
        {Object.entries(cData).map(([key, values]) => (
          <div className="col-auto">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={values}
                id="flexCheckDefault"
                onChange={(e) => handleChange(key, values, e)}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {key}
              </label>
            </div>
          </div>
        ))}
      </div>
      <PieChart lbl={lbl} chartData={chartData} />
    </div>
  );
}

export default PieFunction;
