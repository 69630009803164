import { faChartColumn, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import ModalMed from "../modal/DashboardTableModal";
import "chart.js";
import BarChart from "../charts/shape/Bar";
import LineChart from "../charts/shape/Line";
import PieChart from "../charts/shape/Pie";

export default function DataTables({ data, editValue, deleteValue }) {
  const [chartData, setChartData] = useState([]);
  const tableData = data;

  const setupDate = (val) => {
    const date = new Date(val);
    const isoString = date.toISOString();
    const day = isoString.substring(8, 10);
    const month = isoString.substring(5, 7);
    const year = isoString.substring(0, 4);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const columns = [
    {
      name: <h6 className="m-0 table-header fw-bold">Nama</h6>,
      selector: (row) => row.title,
      width: "400px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Instansi</h6>,
      selector: (row) => row.user.agency.name,
      width: "250px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Kategori</h6>,
      selector: (row) => row.category.name,
      center: true,
      width: "200px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Tipe Chart</h6>,
      selector: (row) => row.chartType.toUpperCase(),
      center: true,
      width: "150px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Action</h6>,
      center: true,
      width: "120px",
      selector: (row) => {
        return (
          <div className="row">
            <div className="col">
              <h6>
                <FontAwesomeIcon
                  onClick={() => setChartData(row)}
                  data-bs-toggle="modal"
                  data-bs-target="#modalMed"
                  icon={faChartColumn}
                  role={"button"}
                  className="align-middle"
                />
              </h6>
            </div>

            <div className="col">
              <h6>
                <FontAwesomeIcon
                  onClick={() => editValue(row)}
                  data-bs-toggle="modal"
                  data-bs-target="#updateData"
                  icon={faEdit}
                  role={"button"}
                  className="align-middle"
                />
              </h6>
            </div>

            <div className="col">
              <h6>
                <FontAwesomeIcon
                  onClick={() => deleteValue(row)}
                  data-bs-toggle="modal"
                  data-bs-target="#deleteData"
                  icon={faTrash}
                  role={"button"}
                  className="align-middle"
                />
              </h6>
            </div>
          </div>
        );
      },
    },
  ];

  const body = () => {
    if (chartData.chartType === "bar") {
      return (
        <div className="container bg-white">
          <BarChart
            // title={chartData.title}
            lbl={chartData.chartData.lbl}
            data={chartData.chartData.chartData}
            update={setupDate(chartData.updatedAt)}
            category={chartData.category.name}
            agency={chartData.user.agency.name}
          />
        </div>
      );
    } else if (chartData.chartType === "line") {
      return (
        <div className="container bg-white">
          <LineChart
            // title={chartData.title}
            x={chartData.chartData.x}
            data={chartData.chartData.checkedItems}
            update={setupDate(chartData.updatedAt)}
            category={chartData.category.name}
            agency={chartData.user.agency.name}
          />
        </div>
      );
    } else if (chartData.chartType === "pie") {
      return (
        <div className="container bg-white">
          <PieChart
            // title={chartData.title}
            lbl={chartData.chartData.lbl}
            chartData={chartData.chartData.chartData}
            update={setupDate(chartData.updatedAt)}
            category={chartData.category.name}
            agency={chartData.user.agency.name}
          />
        </div>
      );
    }
  };
  return (
    <div>
      <DataTable columns={columns} data={tableData} pagination dense />
      <ModalMed title={chartData.title} body={() => body()} jsonData={chartData.jsonData} />
    </div>
  );
}
