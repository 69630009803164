import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faClose, faDashboard, faList, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Sidebar({ action, active }) {
  const level = localStorage.getItem("level");
  const [activeMenu, setActiveMenu] = useState("dashboard");

  function handleClick(menu) {
    setActiveMenu(menu);
    action(menu);
  }

  return (
    <div className="p-4">
      <div id="closeSidebar" className="container text-end">
        <FontAwesomeIcon icon={faClose} onClick={active} />
      </div>

      <ul className="list-unstyled components mb-5 menu-elements">
        <li className="nav-item">
          <Link
            onClick={() => handleClick("dashboard")}
            className={`nav-link ${activeMenu === "dashboard" ? "active" : ""}`}
          >
            <FontAwesomeIcon icon={faDashboard} className="me-2" />
            Dashboard
          </Link>
        </li>

        {level < 2 ? (
          <li className="nav-item">
            <Link
              onClick={() => handleClick("category")}
              className={`nav-link ${activeMenu === "category" ? "active" : ""}`}
            >
              <FontAwesomeIcon icon={faList} className="me-2" />
              Kategori
            </Link>
          </li>
        ) : null}

        {level < 2 ? (
          <li className="nav-item">
            <Link onClick={() => handleClick("skpd")} className={`nav-link ${activeMenu === "skpd" ? "active" : ""}`}>
              <FontAwesomeIcon icon={faBuilding} className="me-2" />
              SKPD
            </Link>
          </li>
        ) : null}

        {level < 2 ? (
          <li className="nav-item">
            <Link onClick={() => handleClick("users")} className={`nav-link ${activeMenu === "users" ? "active" : ""}`}>
              <FontAwesomeIcon icon={faUsersGear} className="me-2" />
              User Manager
            </Link>
          </li>
        ) : null}
      </ul>
    </div>
  );
}
