import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../utils/Axios";

export default function Forgot() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleGoTo = (uri) => {
    navigate(uri);
  };

  const handleForgot = async (event) => {
    event.preventDefault()
    await axios
      .get(`/api/auth/forgot?email=${email}`)
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((result) => {
        toast.error(result.data.message);
      });

      setEmail("")
  };

  return (
    <div className="login-container d-flex align-items-center justify-content-center">
      <div className="container login-inner-container text-center">
        <img id="forgot-icon" src={require("../../assets/images/pemko.png")} alt="icon" className="img-responsive" />
        <form className="mb-4 mt-5" id="forgotForm" onSubmit={handleForgot}>
          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="email"
                className="form-control ps-2 ipt"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </form>
        <div className="row">
          <button type="submit" form="forgotForm" className="btn btn-primary btn-md w-100 rounded-pill">
            Kirim Reset Link
          </button>
        </div>
        <div className="container p-0 text-start mt-1">
          <small class="form-text text-muted">Link reset akan dikirimkan ke email anda.</small>
        </div>
        <div className="row mt-5">
          <div className="container text-orange">
            <span role={"button"} onClick={() => handleGoTo("/")}>
              Home
            </span>
            {" / "}
            <span role={"button"} onClick={() => handleGoTo("/login")}>
              Login
            </span>
            {" / "}
            <span role={"button"} onClick={() => handleGoTo("/register")}>
              Register
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
