import React, { useEffect, useState } from "react";
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";

export default function UpdateAgency({ data, refreshTable }) {
  const [agencyName, setAgencyName] = useState(null);
  const [agencyAddress, setAgencyAddress] = useState(null);
  const [agencyPhone, setAgencyPhone] = useState(null);

  const clearData = () => {
    setAgencyName(null);
    setAgencyAddress(null);
    setAgencyPhone(null);
  };

  useEffect(() => {
    setAgencyName(data.name);
    setAgencyAddress(data.address);
    setAgencyPhone(data.phone);
  }, [data])

  const editAgency = async () => {
    const token = localStorage.getItem("token");
    const d = {
      name: agencyName ? agencyName : data.name,
      address: agencyAddress ? agencyAddress : data.address,
      phone: agencyPhone ? agencyPhone : data.phone,
    };
    await axios
      .post(`/api/agency/update/${data.id}`, d, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.data.success) {
          refreshTable();
          clearData();
          toast.success(result.data.message)
        } else {
          toast.error(result.data.message);
        }
      });
  };

  return (
    <div
      className="modal fade"
      id="updateAgency"
      tabIndex={"-1"}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit SKPD
            </h5>
            <button
              onClick={() => clearData()}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Nama SKPD"
                defaultValue={data.name}
                onChange={(e) => setAgencyName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Alamat SKPD"
                defaultValue={data.address}
                onChange={(e) => setAgencyAddress(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Telepon SKPD"
                defaultValue={data.phone}
                onChange={(e) => setAgencyPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => clearData}
              type="clear"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() => editAgency()}
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
