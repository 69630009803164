/* eslint-disable array-callback-return */
import React from "react";
import LineChart from "../../charts/shape/Line";
import BarChart from "../../charts/shape/Bar";
import PieChart from "../../charts/shape/Pie";
import "chart.js";

export default function ChartView({ data }) {
  const setupDate = (val) => {
    const date = new Date(val);
    const isoString = date.toISOString();
    const day = isoString.substring(8, 10);
    const month = isoString.substring(5, 7);
    const year = isoString.substring(0, 4);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  return (
    <div className="row">
      {data.map((val) => {
        if (val.chartType === "bar") {
          return (
            <div className="col-md-6 mb-2">
              <BarChart
                lbl={val.chartData.lbl}
                data={val.chartData.chartData}
                title={val.title}
                update={setupDate(val.updatedAt)}
                category={val.category.name}
                agency={val.user.agency.name}
              />
            </div>
          );
        } else if (val.chartType === "line") {
          return (
            <div className="col-md-6 mb-2">
              <div className="container bg-white">
                <LineChart
                  x={val.chartData.x}
                  data={val.chartData.checkedItems}
                  title={val.title}
                  update={setupDate(val.updatedAt)}
                  category={val.category.name}
                  agency={val.user.agency.name}
                />
              </div>
            </div>
          );
        } else if (val.chartType === "pie") {
          return (
            <div className="col-md-6 mb-2">
              <div className="container bg-white">
                <PieChart
                  lbl={val.chartData.lbl}
                  chartData={val.chartData.chartData}
                  title={val.title}
                  update={setupDate(val.updatedAt)}
                  category={val.category.name}
                  agency={val.user.agency.name}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
