import { faChartColumn, faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "chart.js";
import BarChart from "../charts/shape/Bar";
import LineChart from "../charts/shape/Line";
import PieChart from "../charts/shape/Pie";
import axios from "../../utils/Axios";
import PublicModal from "../modal/PublicModal";

export default function PublicTable() {
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [category, setCategory] = useState([]);
  const [agency, setAgency] = useState([]);
  const [selected, setSelected] = useState("Semua");
  const [displayData, setDisplayData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  // const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [userDinas, setUserDinas] = useState(0);

  const getData = async (page) => {
    await axios.get(`/api/public/data?page=${page}`).then((result) => {
      if (result.data.success) {
        setTableData(result.data.result);
        setTotalData(result.data.totalData);
        setDisplayData(result.data.result);
        // setTotalPages(result.data.totalPages);
        setCurrentPage(result.data.currentPage);
        setTotalCount(result.data.totalCount);
      }
    });

    await axios.get("/api/public/category").then((result) => {
      setCategory(result.data.result);
    });

    await axios.get("/api/public/agency").then((result) => {
      setAgency(result.data.result);
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (perPage, page) => {
    setCurrentPage(page);
    setPageSize(perPage);
  };

  async function handleCatClick(val) {
    setSelected(val);
    if (val === "Semua") {
      setDisplayData(tableData);
    } else {
      let f = tableData.filter((vals) => vals.category.name === val);
      setDisplayData(f);
    }
  }

  const searching = async (d) => {
    await axios.get(`/api/public/search?dinas=${d}&q=${searchQuery}`).then((result) => {
      setDisplayData(result.data.result);
      setTotalCount(result.data.totalCount);
    });
  };

  const handleDinasFilter = async (event) => {
    setUserDinas(event.target.value);
    searching(event.target.value);
  };

  const handleSearch = async (event) => {
    if (event.key === "Enter") {
      searching(userDinas);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const handleMouseEnter = (event, row) => {
    setShowPopup(true);
    setPopupPosition({ x: event.clientX, y: event.clientY });
    setChartData(row);
  };

  const setupDate = (val) => {
    const date = new Date(val);
    const isoString = date.toISOString();
    const day = isoString.substring(8, 10);
    const month = isoString.substring(5, 7);
    const year = isoString.substring(0, 4);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const columns = [
    {
      name: <h6 className="m-0 table-header fw-bold">Nama Data</h6>,
      selector: (row) => row.title,
      width: "40%",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Instansi</h6>,
      selector: (row) => row.user.agency.name,
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Kategori</h6>,
      selector: (row) => row.category.name,
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Tipe Chart</h6>,
      selector: (row) => row.chartType.toUpperCase(),
      center: true,
      width: "100px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Update Tanggal</h6>,
      selector: (row) => setupDate(row.updatedAt),
      center: true,
      width: "150px",
    },
    {
      name: <h6 className="m-0 table-header fw-bold">Tampilkan</h6>,
      width: "100px",
      center: true,
      selector: (row) => {
        return (
          <div className="row">
            <div className="col">
              <h6>
                <FontAwesomeIcon
                  onClick={() => setChartData(row)}
                  data-bs-toggle="modal"
                  data-bs-target="#publicModal"
                  icon={faChartColumn}
                  role={"button"}
                  className="align-middle"
                  onMouseEnter={(event) => handleMouseEnter(event, row)}
                  onMouseLeave={() => setShowPopup(false)}
                />
              </h6>
            </div>
          </div>
        );
      },
    },
  ];

  const body = () => {
    if (chartData.chartType === "bar") {
      return (
        <BarChart
          // title={chartData.title}
          lbl={chartData.chartData.lbl}
          data={chartData.chartData.chartData}
          update={setupDate(chartData.updatedAt)}
          category={chartData.category.name}
          agency={chartData.user.agency.name}
        />
      );
    } else if (chartData.chartType === "line") {
      return (
        <div className="container bg-white">
          <LineChart
            // title={chartData.title}
            x={chartData.chartData.x}
            data={chartData.chartData.checkedItems}
            update={setupDate(chartData.updatedAt)}
            category={chartData.category.name}
            agency={chartData.user.agency.name}
          />
        </div>
      );
    } else if (chartData.chartType === "pie") {
      return (
        <div className="container bg-white">
          <PieChart
            // title={chartData.title}
            lbl={chartData.chartData.lbl}
            chartData={chartData.chartData.chartData}
            update={setupDate(chartData.updatedAt)}
            category={chartData.category.name}
            agency={chartData.user.agency.name}
          />
        </div>
      );
    }
  };

  const Popup = ({ x, y }) => {
    return (
      <div className="border" style={{ position: "fixed", left: x - 450, top: y + 30 }}>
        {body()}
      </div>
    );
  };

  return (
    <div className="position-relative">
      {/* <div className="container-fluid mb-3">
        <li className="d-flex">
          <ul
            id="home-table-menu"
            role={"button"}
            onClick={() => handleCatClick("Semua")}
            className={`m-0 me-1 rounded ${selected === "Semua" ? "active" : ""}`}
          >
            <span style={{ fontSize: 12 }}>{"Semua"}</span>{" "}
            <span style={{ fontSize: 12 }} className="badge">
              {totalData}
            </span>
          </ul>
          {category.map((val) => {
            return (
              <ul
                key={val.id}
                id="home-table-menu"
                role={"button"}
                onClick={() => handleCatClick(val.name)}
                className={`m-0 me-1 rounded ${selected === val.name ? "active" : ""}`}
              >
                <span style={{ fontSize: 12 }}>{val.name}</span>{" "}
                <span style={{ fontSize: 12 }} className="badge">
                  {val.data.length}
                </span>
              </ul>
            );
          })}
        </li>
      </div> */}
      <div className="container mb-3 shadow">
        <div className="row">
          <div className="col-md-3 bg-white border-end p-0">
            <div className="input-group">
              <label className="input-group-text bg-white border-0" htmlFor="#public-select-skpd">
                <FontAwesomeIcon icon={faList} />
              </label>
              <select
                onChange={handleDinasFilter}
                id="public-select-skpd"
                className="form-select form-select-lg ipt"
                aria-label="Filter select"
              >
                <option value={0}>Pilih Dinas</option>
                {agency.map((val, index) => {
                  return (
                    <option key={index} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="col-md-9 bg-white p-0">
            <div className="input-group">
              <input
                id="public-search"
                onKeyDown={handleSearch}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control form-control-lg ipt"
                type={"text"}
                placeholder="Cari Data ..."
              ></input>
              <label
                role={"button"}
                onClick={searching}
                className="input-group-text bg-white border-0"
                htmlFor="#public-search"
              >
                <FontAwesomeIcon style={{ color: "grey" }} icon={faSearch} />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-3 shadow p-0">
      <DataTable
        columns={columns}
        data={displayData}
        pagination
        paginationServer
        // paginationTotalRows={totalPages * pageSize}
        paginationTotalRows={totalCount}
        paginationPerPage={pageSize}
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
        // paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        dense
      />
      </div>
      
      {showPopup && <Popup x={popupPosition.x} y={popupPosition.y} />}
      <PublicModal title={chartData.title} body={() => body()} jsonData={chartData.jsonData} />
    </div>
  );
}
