import React, { useEffect, useState } from "react";
import CategoryTables from "../../tables/CategoryTable";
import axios from "../../../utils/Axios";
import InsertCategory from "../../modal/categories/Insert";
import { toast } from "react-toastify";

export default function Category({ action, total }) {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");

  const getData = async () => {
    await axios
      .get("/api/category", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.result);
        total(result.data.result.length);
      });
  };

  const createData = async (name) => {
    const formData = {
      name: name,
    };
    await axios.post("/api/category/create", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(async (res) => {
      if (res.data.success) {
        await getData();
        toast.success(res.data.message);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container mt-2">
      <CategoryTables data={data} editAction={(id) => alert(id)} refreshTable={() => getData()} />
      <InsertCategory title={"Tambah Kategory"} submit={(name) => createData(name)} />
    </div>
  );
}
