import React, { useEffect, useState } from "react";
import axios from "../../../utils/Axios";
import InsertAgency from "../../modal/agencies/Insert";
import AgencyTable from "../../tables/AgencyTable";
import { toast } from "react-toastify";

export default function Agency({ total }) {
  const [skpd, setSkpd] = useState([]);
  const token = localStorage.getItem("token");

  const getAgencies = async () => {
    await axios
      .get("/api/agency", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.data.success) {
          setSkpd(result.data.result);
          total(result.data.result.length);
        } else {
          toast.error(result.data.message);
        }
      });
  };

  const createData = async (agencyName, agencyAddress, agencyPhone) => {
    const formData = {
      name: agencyName,
      address: agencyAddress,
      phone: agencyPhone,
    };
    await axios
      .post("/api/agency/create", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        if (res.data.success) {
          await getAgencies();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    getAgencies();
  }, []);

  return (
    <div>
      <AgencyTable data={skpd} refreshTable={() => getAgencies()} />
      <InsertAgency
        submit={(agencyName, agencyAddress, agencyPhone) => createData(agencyName, agencyAddress, agencyPhone)}
      />
    </div>
  );
}
