import React, { useEffect, useState } from "react";
import XlsxToJson from "../../../utils/XlsxToJson";
import InsertTables from "../../tables/InsertTable";
import Line from "../../charts/LineFunction";
import BarChart from "../../charts/BarFunction";
import PieChart from "../../charts/PieFunction";
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";


export default function ModalUpdate({ catData, dataToEdit, refreshData }) {
  const [jsonData, setJsonData] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [dataSubmit, setDataSubmit] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const token = localStorage.getItem("token");

  const resetData = () => {
    setJsonData(null);
    setTitle("");
    setSelectedCat("");
    setDataSubmit([]);
    setSelectedOption("");
  };

  const updateData = async () => {
    const formData = {
      chartData: dataSubmit,
      jsonData: jsonData,
      title: title,
      chartType: selectedOption,
      categoryId: selectedCat,
    };

    await axios
      .post(`/api/data/update/${dataToEdit.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
          refreshData();
          resetData();
        } else {
          toast.error(result.data.message)
        }
      });
  };

  useEffect(() => {
    setJsonData(dataToEdit.jsonData);
    setTitle(dataToEdit.title);
    setSelectedOption(dataToEdit.chartType);
    setSelectedCat(dataToEdit.category ? dataToEdit.category.id : 0);
  }, [dataToEdit]);

  return (
    <div className="modal fade" id="updateData" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Data
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="h-100">
              <form id="updateForm">
                <div className="row d-flex align-items-center justify-content-center text-white bg-dark rounded pt-3 pb-3 h-25">
                  <div className="col-auto">
                    <div className="container">
                      <label htmlFor="insertTitle">Upload File : </label>
                      <XlsxToJson jsonData={(val) => setJsonData(val)} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="container form-inline">
                      <label htmlFor="insertTitle">Nama Data :</label>
                      <input
                        type="text"
                        className="form-control"
                        id="insertTitle"
                        defaultValue={dataToEdit.title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="container">
                      <label htmlFor="insertChart">Pilih Kategori :</label>
                      <select
                        className="form-select"
                        id="insertChart"
                        aria-label="Default select example"
                        onChange={(e) => setSelectedCat(e.target.value)}
                      >
                        <option value={null} selected>
                          --- Kategori ---
                        </option>
                        {catData.map((category) => (
                          <option
                            key={category.id}
                            value={category.id}
                            selected={dataToEdit.category ? category.id === dataToEdit.category.id : null}
                          >
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="container">
                      <label htmlFor="insertChart">Pilih tipe chart :</label>
                      <select
                        className="form-select"
                        id="insertChart"
                        aria-label="Default select example"
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value={null} selected>
                          --- Chart ---
                        </option>
                        <option selected={dataToEdit.chartType ? dataToEdit.chartType === "line" : null} value="line">
                          Line Chart
                        </option>
                        <option selected={dataToEdit.chartType ? dataToEdit.chartType === "bar" : null} value="bar">
                          Bar Chart
                        </option>
                        <option selected={dataToEdit.chartType ? dataToEdit.chartType === "pie" : null} value="pie">
                          Pie Chart
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row mt-3 h-75">
                <div className="col-md-6">
                  <div className="container">
                    <InsertTables tData={jsonData ? jsonData : dataToEdit.jsonData ? dataToEdit.jsonData : []} />
                  </div>
                </div>
                <div className="col-md-6">
                  {selectedOption === "line" ? (
                    <Line
                      cData={jsonData ? jsonData : dataToEdit.jsonData ? dataToEdit.jsonData : []}
                      submitData={(dt) => setDataSubmit(dt)}
                      // editData={dataToEdit.chartData ? dataToEdit.chartData : null}
                    />
                  ) : selectedOption === "bar" ? (
                    <BarChart
                      cData={jsonData ? jsonData : dataToEdit.jsonData ? dataToEdit.jsonData : []}
                      submitData={(dt) => setDataSubmit(dt)}
                      // editData={dataToEdit.chartData ? dataToEdit.chartData : null}
                    />
                  ) : selectedOption === "pie" ? (
                    <PieChart
                      cData={jsonData ? jsonData : dataToEdit.jsonData ? dataToEdit.jsonData : []}
                      submitData={(dt) => setDataSubmit(dt)}
                      // editData={dataToEdit.chartData ? dataToEdit.chartData : null}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              form="#updateForm"
              onClick={() => updateData()}
              type="clear"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
