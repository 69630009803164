import React, { useState, useEffect } from "react";
import Bar from "./shape/Bar";
import "chart.js";

function BarChart({ cData, submitData, editData }) {
  const [lbl, setLbl] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let dt = { lbl: lbl, chartData: chartData };
    submitData(dt);
    // setLbl(editData ? editData.lbl : [])
  }, [lbl, chartData, submitData]);

  const processTheData = async (key, val, event) => {
    if (event.target.checked) {
      if (!lbl.includes(key)) {
        setChartData([
          ...chartData,
          {
            label: key,
            data: val,
            backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
            borderWidth: 1,
          },
        ]);
      }
    } else {
      setChartData(chartData.filter((item) => item.label !== key));
    }
  };

  const handleChange = async (key, val, event) => {
    await processTheData(key, val, event);
  };

  const handleLabelChange = async (key, val, event) => {
    setLbl(val);
  };

  return (
    <div>
      <div className="row border rounded p-2 mb-2">
        <p>Pilih Agregat :</p>
        {Object.entries(cData).map(([key, values]) => (
          <div className="col-auto">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={values}
                id="flexCheckDefault"
                onChange={(e) => handleLabelChange(key, values, e)}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {key}
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className="row border rounded p-2">
        {Object.entries(cData).map(([key, values]) => (
          <div className="col-auto">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={values}
                id="flexCheckDefault"
                onChange={(e) => handleChange(key, values, e)}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {key}
              </label>
            </div>
          </div>
        ))}
      </div>

      <Bar data={chartData} lbl={lbl} />
    </div>
  );
}

export default BarChart;
