import {
  faBuilding,
  faEnvelope,
  faIdCard,
  faLock,
  faMapMarker,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../utils/Axios";
import { toast } from 'react-toastify';

function Register() {
  const navigate = useNavigate();
  const [agencies, setAgencies] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [password2, setPassword2] = useState("");
  const [password2Error, setPassword2Error] = useState(false);
  const [name, setName] = useState("");
  const [nik, setNik] = useState("");
  const [nikError, setNikError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [agency, setAgency] = useState("");
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState(false);
  const [address, setAddress] = useState("");

  const handleRegister = async () => {
    if (
      password === password2 &&
      !password2Error &&
      !emailError &&
      !nikError &&
      !contactError &&
      name &&
      nik &&
      email &&
      agency &&
      password &&
      address &&
      contact
    ) {
      const formData = {
        name: name,
        nik: nik,
        email: email,
        agency: agency,
        password: password,
        address: address,
        contact: contact,
      };

      const res = await axios.post("/api/auth/register", formData);
      if (res.data.success) {
        navigate("/login");
      } else {
        toast.success(res.data.message)
      }
    } else {
      toast.error("Please fill all the required fields with correct data")
    }
  };

  const getAgencies = async () => {
    await axios.get("/api/public/agencies").then((result) => {
      setAgencies(result.data.result);
    });
  };

  const changeName = (e) => {
    const titleCaseName = e.target.value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setName(titleCaseName);
  };

  const changeAddress = (e) => {
    const titleCaseName = e.target.value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setAddress(titleCaseName);
  };

  const changeNik = (e) => {
    setNik(e.target.value);
    setNikError(e.target.value.length !== 16);
  };

  const changeDinas = (event) => {
    setAgency(event.target.value);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(!validateEmail(e.target.value));
  };

  const validateContact = (contact) => {
    const re = /^\d{10,}$/;
    return re.test(contact);
  };

  const changeContact = (e) => {
    setContact(e.target.value);
    setContactError(!validateContact(e.target.value));
  };

  const validatePassword = (password) => {
    if (password.length < 6 || !/\d/.test(password)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const Password2Change = (e) => {
    setPassword2(e.target.value);
    if (password !== e.target.value) {
      setPassword2Error(true);
    } else {
      setPassword2Error(false);
    }
  };

  useEffect(() => {
    getAgencies();
  }, []);

  return (
    <div className="login-container d-flex align-items-center justify-content-center">
      <div className="container login-inner-container text-center">
        <img id="login-icon" src={require("../../assets/images/pemko.png")} alt="icon" className="img-responsive" />
        <form className="mb-4 mt-5  text-start">
          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="text"
                className="form-control ps-2 ipt"
                placeholder="Nama"
                value={name}
                onChange={changeName}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faIdCard} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="text"
                pattern="\d{0,16}"
                className={`form-control ps-2 ipt`}
                placeholder="NIK"
                style={nikError ? { color: "red" } : {}}
                value={nik}
                onChange={changeNik}
                maxLength={16}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="email"
                className="form-control ps-2 ipt"
                placeholder="Email"
                style={emailError ? { color: "red" } : {}}
                value={email}
                onChange={changeEmail}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <select
                className="form-select border-0 ipt"
                aria-label="Default select example"
                onChange={changeDinas}
                defaultValue={null}
              >
                <option value={null}>Pilih Nama Dinas</option>
                {agencies.length > 0
                  ? agencies.map((val) => {
                      return (
                        <option key={val.id} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="phone"
                className="form-control ps-2 ipt"
                placeholder="Kontak"
                style={contactError ? { color: "red" } : {}}
                value={contact}
                onChange={changeContact}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faMapMarker} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="text"
                className="form-control ps-2 ipt"
                placeholder="Alamat"
                value={address}
                onChange={changeAddress}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="password"
                className={`form-control ps-2 ipt ${passwordError ? "is-invalid" : ""}`}
                placeholder="Password"
                value={password}
                onChange={passwordChange}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="password"
                className={`form-control ps-2 ipt ${password2Error ? "is-invalid" : ""}`}
                placeholder="Confirm Password"
                value={password2}
                onChange={Password2Change}
              />
            </div>
          </div>
        </form>
        <div className="row">
          <button type="submit" onClick={handleRegister} className="btn btn-primary btn-md w-100 rounded-pill">
            Registrasi
          </button>
          <div className="container text-orange mt-3">
            <span role={"button"} onClick={() => navigate("/login")}>
              Login
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
