import React from "react";
import { Line } from "react-chartjs-2";

export default function LineChart({ x, data, title, update, category, agency }) {
  return (
    <div className="container w-100 bg-white overflow-auto">
      {title ? <h6 className="pt-2">{title}</h6> : null}
      <div style={{ width: x.length * 100, height: "50vh" }}>
        <Line
          data={{
            labels: x,
            datasets: data,
          }}
          options={{
            plugins: {
              legend: {
                align: "start",
                display: true,
              },
            },
            elements: {
              line: {
                tension: 0.3,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
      {update ? (
        <span className="small" style={{ fontSize: 12 }}>
          Updated : {update} | Kategori : {category} | Dinas : {agency}
        </span>
      ) : null}
    </div>
  );
}
