import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Cctv from "../apis/Cctv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";

export default function VideoPlayer() {
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const onClose = () => {
    setShowVideo(false);
    setVideoUrl(null);
  };

  const onClick = async (row) => {
    setVideoUrl(row.vidUrl);
    setShowVideo(true);
  };

  const columns = [
    {
      name: "Lokasi",
      selector: (row) => row.nama_lokasi,
      width: "80%",
    },
    {
      name: "Putar",
      selector: (row) => <FontAwesomeIcon icon={faVideoCamera} onClick={() => onClick(row)} />,
      width: "20%",
      center: true,
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <DataTable columns={columns} data={Cctv} pagination />
        </div>
        <div className="col-md-8 bg-white p-3 d-flex align-items-center justify-content-center v-player-container my-2 rounded">
          {showVideo && (
            <div>
              <ReactPlayer url={videoUrl} playing muted controls />
              <div className="text-center">
                <button onClick={onClose} className="btn btn-success mt-3">
                  Tutup CCTV
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
