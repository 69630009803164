import React, { useState } from "react";
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";

export default function UpdateCategory({ data, refreshTable }) {
  const token = localStorage.getItem("token");
  const [editName, setEditname] = useState("");

  const editData = async (event) => {
    event.preventDefault();
    await axios
      .get(`/api/category/edit/${data.id}/${editName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        toast.success(result.data.message)
        refreshTable();
      });
  };

  return (
    <div
      className="modal fade"
      id="updateCategory"
      tabIndex={"-1"}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Kategori
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form id="updateCategoryForm" onSubmit={editData}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Kategori"
                  defaultValue={data.name}
                  onChange={(e) => setEditname(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button form="updateCategoryForm" type="clear" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button form="updateCategoryForm" type="submit" className="btn btn-primary" data-bs-dismiss="modal">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
