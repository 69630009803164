import React, { useState, useEffect } from "react";
import Slider from "../components/home/Slider";
// import WeatherCard from "../utils/Weather";
import { useNavigate } from "react-router-dom";
import LinedText from "../components/LinedText";
import Economy from "../components/news/Economy";
import Politic from "../components/news/Politic";
import Education from "../components/news/Education";
import Health from "../components/news/Health";
import Footer from "../components/Footer";
import PublicTable from "../components/tables/PublicTable";
import VideoList from "../utils/VideoPlayer";
import axios from "../utils/Axios";
// import LattestUpdate from "../components/home/LattestUpdate";

export default function Home2() {
  const [navbarClass, setNavbarClass] = useState("navbar navbar-expand-lg");
  const [iconClass, seticonClass] = useState("text-orange");
  const [isLogin, setIslogin] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    if (isLogin) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const loginCheck = async () => {
    await axios
      .get("/api/user/me")
      .then((response) => {
        if (response.data.success) {
          setIslogin(true);
        } else {
          setIslogin(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setIslogin(false);
        } else {
          setIslogin(false);
        }
      });
  };

  useEffect(() => {
    loginCheck();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setNavbarClass("navbar navbar-expand-lg bg-light shadow");
        seticonClass("text-dark");
      } else {
        setNavbarClass("navbar navbar-expand-lg");
        seticonClass("text-orange");
      }
    });
  }, []);

  return (
    <div className="wraps">
      <div className="container">
        <nav className={navbarClass} id="home-nav">
          <div className="container">
            <a className="navbar-brand" href="/home">
              <img src={require("../assets/images/pemko.png")} alt="pemko" style={{ height: "6vh" }} />{" "}
              <span className={iconClass} style={{ fontWeight: "bold" }}>
                Pemko Medan
              </span>
            </a>

            <button onClick={login} className="btn btn-primary">
              {isLogin ? "Dashboard" : "LOGIN"}
            </button>
          </div>
        </nav>
      </div>

      {/* <div style={{ height: "8vh" }}></div> */}

      <div style={{ height: "100vh" }}>
        {/* <div className="container h-100 w-100 d-flex justify-content-center align-items-center">
          <div id="home-banner" className="row" style={{ zIndex: 2 }}>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="container p-0 m-0 w-100">
                <h1 className="display-3 fw-bold text-white">Selamat Datang</h1>
                <h1 className="display-4 fw-bold text-orange">Di Aplikasi Medan Satu Data</h1>
              </div>
            </div>
            <div className="col-md-6 text-end">
              <img src={require("../assets/images/walkot.png")} alt="" className="w-100" />
            </div>
          </div>
        </div> */}
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
          <Slider />
        </div>
      </div>

      {/* <div className="container mt-5 mb-5">
        <WeatherCard />
      </div> */}

      
      <div id="dataTable" className="container mb-5 mt-3">
      <LinedText text={"Dataset"} />
        <PublicTable />
      </div>

      {/* <LinedText text={"Paling Update"} />
      <div className="container mb-5 mt-3">
        <LattestUpdate />
      </div> */}

      <div id="pantauMedan" className="container mb-3">
        <LinedText text={"Pantau Kota Medan"} />
        <VideoList />
      </div>

      <div className="container mb-3">
        <LinedText text={"Ekonomi"} />
        <Economy />
      </div>

      <div className="container mb-3">
        <LinedText text={"Politik"} />
        <Politic />
      </div>

      <div className="container mb-3">
        <LinedText text={"Pendidikan"} />
        <Education />
      </div>

      <div className="container mb-3">
        <LinedText text={"Kesehatan"} />
        <Health />
      </div>

      <Footer />
    </div>
  );
}
