import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import axios from "../../utils/Axios";

export default function Reset() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [password2, setPassword2] = useState("");
  const [password2Error, setPassword2Error] = useState(false);

  const handleGoTo = (uri) => {
    navigate(uri);
  };

  const handleReset = async (event) => {
    event.preventDefault();
    await axios
      .post("/api/auth/reset-password", {
        token: token,
        newPassword: password,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          navigate("/login")
        } else {
          toast.error(res.data.message);
        }
      }).catch(result => {
        toast.error(result.data.message);
      });

      setPassword("")
      setPassword2("")
  };

  const validatePassword = (password) => {
    if (password.length < 6 || !/\d/.test(password)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const Password2Change = (e) => {
    setPassword2(e.target.value);
    if (password !== e.target.value) {
      setPassword2Error(true);
    } else {
      setPassword2Error(false);
    }
  };

  return (
    <div className="login-container d-flex align-items-center justify-content-center">
      <div className="container login-inner-container text-center">
        <img id="reset-icon" src={require("../../assets/images/pemko.png")} alt="icon" className="img-responsive" />
        <form className="mb-4 mt-5" id="resetForm" onSubmit={handleReset}>
        <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="password"
                className={`form-control ps-2 ipt ${passwordError ? "is-invalid" : ""}`}
                placeholder="Password"
                value={password}
                onChange={passwordChange}
              />
            </div>
          </div>

          <div className="row bg-light rounded-pill ps-3 pe-3 mb-2">
            <div className="col-auto d-flex justify-content-center align-items-center p-0">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="col p-0 w-100 h-100 d-flex align-items-center">
              <input
                type="password"
                className={`form-control ps-2 ipt ${password2Error ? "is-invalid" : ""}`}
                placeholder="Confirm Password"
                value={password2}
                onChange={Password2Change}
              />
            </div>
          </div>
        </form>
        <div className="row">
          <button type="submit" form="resetForm" className="btn btn-primary btn-md w-100 rounded-pill">
            Reset Password
          </button>
        </div>
        <div className="container p-0 text-start mt-1">
          <small className="form-text text-muted">OTP akan dikirimkan ke email anda.</small>
        </div>
        <div className="row mt-5">
          <div className="container text-orange">
            <span role={"button"} onClick={() => handleGoTo("/")}>
              Home
            </span>
            {" / "}
            <span role={"button"} onClick={() => handleGoTo("/login")}>
              Login
            </span>
            {" / "}
            <span role={"button"} onClick={() => handleGoTo("/register")}>
              Register
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
