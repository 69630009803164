import React, { useState } from "react";
import JsonToXlsx from "../../utils/JsonToXlsx";
import FunctionalTable from "../tables/FunctionalTable";

export default function PublicModal({ title, body, submit, jsonData }) {
  const [chartView, setChartView] = useState(true);

  return (
    <div className="modal fade" id="publicModal" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">{chartView ? body() : <FunctionalTable jsonData={jsonData} />}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() => setChartView(!chartView)}>
              {chartView ? "Tampilkan Tabel" : "Tampilkan Chart"}
            </button>
            <JsonToXlsx jsonData={jsonData} title={title} />
            <button
              onClick={() => setChartView(true)}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
